define("discourse/plugins/discourse-voting/discourse/routes/user-activity-votes", ["exports", "discourse/routes/user-topic-list", "discourse/models/user-action"], function (_exports, _userTopicList, _userAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _userTopicList.default.extend({
    userActionType: _userAction.default.TYPES.topics,
    model: function () {
      return this.store.findFiltered("topicList", {
        filter: "topics/voted-by/" + this.modelFor("user").get("username_lower")
      });
    }
  });
});